html, body {
    margin: 0;
    padding: 0;
}


/* === LoginPage === */

.loginPage-button {
    background-color: #e7521f;
}

.loginPage-button:hover {
    background-color: #e72d00;
}

.loginPage-link {
    text-decoration: none;
}

.loginPage-link:hover {
    text-decoration: underline;
}


/* === HomePage === */

.homepage-button {
    background-color: #ffa500; /* Orange color */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.homepage-button:hover {
    background-color: #cc8400; /* Darker shade of orange for hover */
}

.homepage-button:disabled {
    background-color: #ccc; /* Grey color for disabled state */
    cursor: default;
}

